// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-equipe-js": () => import("./../src/pages/equipe.js" /* webpackChunkName: "component---src-pages-equipe-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-materiel-js": () => import("./../src/pages/materiel.js" /* webpackChunkName: "component---src-pages-materiel-js" */),
  "component---src-pages-mentionslegales-js": () => import("./../src/pages/mentionslegales.js" /* webpackChunkName: "component---src-pages-mentionslegales-js" */),
  "component---src-pages-prestations-js": () => import("./../src/pages/prestations.js" /* webpackChunkName: "component---src-pages-prestations-js" */),
  "component---src-templates-prestation-js": () => import("./../src/templates/prestation.js" /* webpackChunkName: "component---src-templates-prestation-js" */)
}

