import React, { useState } from "react"

export const myContext = React.createContext()

const Provider = props => {
  const [isDark, setTheme] = useState(false)
  const [isCookieEnabled, setCookieEnabled] = useState(false)
  return (
    <myContext.Provider
      value={{
        isDark,
        changeTheme: () => setTheme(!isDark),
        isCookieEnabled,
        enableCookie: () => setCookieEnabled(true),
      }}
    >
      {props.children}
    </myContext.Provider>
  )
}

export default ({ element }) => <Provider>{element}</Provider>
